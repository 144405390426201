.default {
    --bg-color: black;
    --font-color: lightslategray;
    --hl-color: orange;
    --fg-color: #292f34;
}

.mkbhd {
    --bg-color: #171717;
    --font-color: #ededed;
    --hl-color: #da0037;
    --fg-color: #444444;
}

.mocha {
    --bg-color: #2d2424;
    --font-color: #e0c097;
    --hl-color: #dc521c;
    --fg-color: #5c3d2e;
}

.coral {
    --bg-color: #fdd2bf;
    --font-color: #df5e5e;
    --hl-color: #492f10;
    --fg-color: #e98580;
}

.ocean {
    --bg-color: #dddddd;
    --font-color: #125d98;
    --hl-color: #f5a962;
    --fg-color: #3c8dad;
}

.azure {
    --bg-color: #383e56;
    --font-color: #eedad1;
    --hl-color: #f69e7b;
    --fg-color: #d4b5b0;
}

.forest {
    --bg-color: #334443;
    --font-color: #fff8df;
    --hl-color: #c6ffc1;
    --fg-color: #34656d;
}

.rose-milk {
    --bg-color: #ffffff;
    --font-color: #111111;
    --hl-color: #b33838;
    --fg-color: #3b8792;
}

.amethyst {
    --bg-color: #e2caea;
    --font-color: #480c51;
    --hl-color: #ab395f;
    --fg-color: #9774aa;
}

.amber {
    --bg-color: #feb204;
    --font-color: #d53600;
    --hl-color: #700e01;
    --fg-color: #ff8503;
}

.terminal {
    --bg-color: #000000;
    --font-color: #ffffff;
    --hl-color: #ff0000;
    --fg-color: #00ff00;
}

.vscode {
    --bg-color: #1e1e1e;
    --font-color: #d4d4d4;
    --hl-color: #ce9178;
    --fg-color: #474747;
}

.mountain {
    --bg-color: #10271e;
    --font-color: #d1ac3f;
    --hl-color: #a2d6f6;
    --fg-color: #485e2c;
}


.red-season {
    --bg-color:#1e1c1c;
    --font-color:#e73535;
    --hl-color: #d4d4d4;
    --fg-color: #593b3e;
}

.pink-sky {
    --bg-color: #23049D;
    --font-color: #FF79CD;
    --hl-color: #FFDF6B;
    --fg-color: #AA2EE6;
}
