table {
    font-size: 16pt;
    font-weight: 800;
    button {
        font-size: 21pt;
    }
}

.wrong {
    color: var(--hl-color);
}
